.horizontal-line:after {
  content: " (or) ";
  padding: 0 4px;
  position: relative;
  top: -13px;
  color: rgba(56, 52, 52, 0.466);
}
.horizontal-line {
  margin: 20px 0px;
}
.error-msg {
  color: red;
  border: 1px solid red;
  font-size: 12px;
  text-align: center;
}
.sigin-bg {
  height: 100vh;
  background-image: linear-gradient(#100028, rgba(0, 0, 0, 0.3)),
    url("assets/images/hero-bg1.jpg");
  background-size: cover;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* .css-uk4jy3-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(22, 21, 21, 0.747) !important;
} */
.forgot-password {
  color: #0000ff85;
}
