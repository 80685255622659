.rest-container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("assets/images/bg-reset-cover.jpeg");
  height: 100vh;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.error-msg {
  color: red;
  border: 1px solid red;
  font-size: 12px;
  text-align: center;
  margin-bottom: 5px;
}
